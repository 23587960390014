@use "sass:math";
@import-normalize;

$polarNight1: #4c566a;
$polarNight2: #434c5e;
$polarNight3: #3b4252;
$polarNight4: #2e3440;
$snowStorm1: #eceff4;
$snowStorm2: #e5e9f0;
$snowStorm3: #d8dee9;
$frost1: #5e81ac;
$frost2: #81a1c1;
$frost3: #88c0d0;
$frost4: #8fbcbb;
$aurora1: #b48ead;
$aurora2: #a3be8c;
$aurora3: #ebcb8b;
$aurora4: #d08770;
$aurora5: #bf616a;

$aspectRatio: math.div(900, 200);

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 90px;
  padding: 0;
  margin: 0;
  background-color: $snowStorm1;
}

body {
  font-family: "Source Code Pro", monospace;
  padding: 0;
  margin: 0;
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.spacer {
  aspect-ratio: 900/200;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("./assets/layered-waves-haikei.svg");
}

@supports not (aspect-ratio: auto) { 
  .spacer::before {
    float: left;
    padding-top: calc((1 / ($aspectRatio))*100%);
    content: "";
  }

  .spacer::after {
    display: block;
    content: "";
    clear: both;
  }
}

.spacer2 {
  aspect-ratio: 900/200;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("./assets/wave-haikei.svg");
}

@supports not (aspect-ratio: auto) { 
  .spacer2::before {
    float: left;
    padding-top: calc((1 / ($aspectRatio))*100%);
    content: "";
  }

  .spacer2::after {
    display: block;
    content: "";
    clear: both;
  }
}

/* 
-------------
Media queries
-------------
 */

@media screen and (max-width: 1200px) {
  .container {
    width: 92%;
  }
}
