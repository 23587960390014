@use '../../index' as *;

/* 
------
Navbar
------
 */

 /* Mobile S and up */
@media only screen and (min-width: 320px) {
    .navbar {
        font-family: 'Roboto', sans-serif;
        background-color: $polarNight2;
        color: $snowStorm1;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 2;

        .navbar-container-lg {
            display: none;
        }
    
        .navbar-container {
            position: relative;
    
            .flex-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1em;
    
                .left-column {
                    min-width: fit-content;
                    a {
                        text-decoration: none;
                        h1 {
                            color: $snowStorm1;
                            font-size: 1.5rem;
                            text-transform: uppercase;
                            margin: 0;
                            float: left;
                            i {
                                color: $aurora3;
                            }
                        }
                    }
                    
                }
        
                .right-column {
                    i {
                        float: right;
                        font-size: 2em;
                    }
                }
            }
    
            .menu {
                z-index: -1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                list-style: none;
                background-color: $polarNight2;
                height: 30vh;
                width: 100vw;
                padding: 0;
                position: fixed;
                top: 0px;
                margin-bottom: 0;
                > * + * {
                    margin-top: .7em;
                }
                li a {
                    text-decoration: none; 
                    text-transform: uppercase;
                    font-size: 20px;
                    font-weight: 700;
                    color: $frost2;
                }
            }
        }
    }
}

/* Laptop and up */
@media (min-width: 1024px) {
    .navbar-container {
        display: none;
    }
    .navbar-container-lg {
        display: inline-block !important;
        width: 100%;

        .flex-container-lg {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1.5em 4em;

            .left-column-lg {
                a {
                    text-decoration: none;
                    h1 {
                        color: $snowStorm1;
                        font-size: 1.75rem;
                        font-weight: 700;
                        text-transform: uppercase;
                        letter-spacing: .2rem;
                        margin: 0;
                        i {
                            color: $aurora3;
                        }
                    }
                }
            }

            .right-column-lg {
                
                .menu-lg {
                    display: flex;
                    align-items: center;
                    list-style: none;
                    margin: 0;
                    li {
                        padding-left: 2em;
                        position: relative;
                        display: inline-block;
                    }
                    li a {
                        font-size: 20px;
                        font-weight: 500;
                        text-decoration: none; 
                        color: $snowStorm1;
                    }

                    .tag:hover {
                        color: $frost2;
                    }
                }
            }
        }
    }
}