@use '../../index' as *;

/* 
-------
Contact
-------
*/

.contact {
    font-family: 'Roboto', sans-serif;
    background-color: $snowStorm1;
    color: $polarNight4;
    overflow: auto;

    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px 20px 4em 20px;
        h1 {
            font-size: 3rem;
            font-weight: 900;
            margin-bottom: 0;
            color: $polarNight2;
        }
        p {
            color: $polarNight3;
            font-weight: 300;
            line-height: 1.75rem;
            max-width: 70ch;
            text-align: center;
            margin-bottom: 2.5rem;
            strong {
                font-weight: 700;
            }
        }
        
        .form-container {
            background: $snowStorm3;
            border-radius: 20px;
            width: 100%;
            margin: 0 20px 20px 20px;
            form {
                width: 100%;
                overflow: hidden;
                input, 
                textarea {
                    border: 2.75px solid transparent;
                    appearance: none;
                    padding: 8px;
                    border-radius: 15px;
                    width: 88%;
                }
                input:focus,
                textarea:focus {
                    outline: none !important;
                    border: 2.75px solid $frost3;
                }
                label,
                input[type="submit"] {
                    font-weight: 400;
                    text-transform: uppercase;
                }
                textarea {
                    min-height: 4em;
                }
                input[type="submit"] {
                    cursor: pointer;
                    color: $snowStorm1;
                    background-color: $polarNight1;
                }
            }
        }
    }
}

/* Tablet and up */
@media (min-width: 768px) {
    form {
        padding: 30px;
        display: grid;
        justify-items: center;
        justify-content: center;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        grid-template-areas:
        "first-name    last-name"
        "email         email"
        "message       message"
        "submit        submit";
        column-gap: 10px;
        p {
            max-width: none !important;
            width: 100%;
        }
        input, 
        textarea {
            width: 100% !important;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        }

        .first-name-area {
            grid-area: first-name;
        }

        .last-name-area {
            grid-area: last-name;
        }

        .email-area {
            grid-area: email;
        }

        .message-area {
            grid-area: message;
        }

        .submit-area {
            grid-area: submit;
        }
    }
}

/* Laptop and up */
@media (min-width: 1024px) {
    form {
        padding: 50px;
        column-gap: 20px;
        input, 
        textarea {
            padding: 15px !important;
        }
    }
}