@use '../../index' as *;

/* 
-------
Project
-------
 */

.card {
    background-image: linear-gradient(20deg, #5e81ac 20%, #81a1c1, #88c0d0, #8fbcbb 90%);
    color: $snowStorm1;
    border-radius: 15px;
    padding: 15px;
    > h2 {
      color: $snowStorm1;
      font-size: 30px;
      font-weight: 900;
      padding: .25em;
      position: relative;
      margin: 25px 0 0 0;
      &::after {
        content: '';
        position: absolute;
        height: 4.5px;
        width: 100%;
        left: 0;
        bottom: 0;
        background: $snowStorm1;
      }
    }
    > p {
      line-height: 1.5rem;
    }      

    .tags {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      list-style: none;
      padding: 0px;
      // gap: .5em;
      > * {
        margin-right: 5px;
        margin-bottom: 5px;
      }
      
      .tag {
        border-radius: 20px;
        font-size: .85rem;
        padding: .45em 1em;
        background-color: $snowStorm1;
        color: $frost1;
      }
    }

    .links {
      display: flex;
      justify-content: center;
      align-items: center;
      // gap: .75em;
      > * + * {
        margin-left: .7em;
      }

      button {
        cursor: pointer;
        border: none;
        border-radius: 20px;
        padding: .45em 1.25em;
        background-color: $frost4;
        color: $snowStorm1;
      }
    }
  }

/* Laptop and up */
@media (min-width: 1024px) {
    .card {
        position: relative;
        padding: 15px;
        > p {
          position: absolute;
          top: 10rem;
          left: 40px;
          right: 40px;
        }
    
        .links {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            button {
            margin-bottom: 15px;
            } 
        }
    }
}
  
/* Laptop L and up */
@media (min-width: 1440px) {
    .card {
      padding: 15px 40px !important;
    }
}