@use '../../index' as *;

/* 
------
Footer
------
*/

.footer {
    font-family: 'Roboto', sans-serif;
    margin-top: auto;

    footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $snowStorm3;

        .copyright,
        .socials {
            color: $polarNight4;
        }

        .copyright {
            min-width: 20px;
        }

        .socials {
            margin-bottom: 20px;
        }

        .socials i {
            color: $polarNight1;
            font-size: 40px;
        }

        /* Selects first 2 children */
        .socials> :nth-child(-n + 2) {
            padding-right: 10px;
        }
    }
}

/* Tablet and up */
@media (min-width: 768px) {
    footer {
        flex-direction: row !important;
        justify-content: space-between;
        padding: 12px;

        .copyright {
            padding-left: 2em;
            margin: 0 !important;
        }

        .socials {
            padding-right: 2em;
            margin: 0 !important;
        }
    }
}