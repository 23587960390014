@use '../../index' as *;

/* 
--------
Projects
--------
 */

.projects {
  font-family: 'Roboto', sans-serif;
  background-color: $polarNight4;
  color: $snowStorm1;
  overflow: auto;

  .projects-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 20px 4em 20px;
    h1 {
      font-size: 3rem;
      font-weight: 900;
      margin-bottom: 0;
      color: $frost2;
    }
    > p {
      font-weight: 300;
      line-height: 1.75rem;
      max-width: 70ch;
      text-align: center;
      margin-bottom: 2.5rem;
      strong {
        font-weight: 700;
        a {
          text-decoration: none;
          color: $snowStorm1;
          &:hover {
            color: $frost2;
          }
        }
      }
    }

    .cards {
      display: grid;
      grid-auto-flow: row;
      row-gap: 15px;
    }
  }
}

/* Laptop and up */
@media (min-width: 1024px) {
  .cards {
    grid-auto-flow: column;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: 25em;
    column-gap: 25px;
  }
}